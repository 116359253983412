'use client'
import React, { useContext } from 'react'
import { Flex } from '@radix-ui/themes'
import { PiArrowBendLeftUpThin, PiArrowBendRightUpThin } from 'react-icons/pi'
import { ChatContext } from '@/components'
import AssistantChat from '@/components/Chat/AssistantChat'
import NewUserMessage from '@/components/Chat/NewUserMessage'

const Page = () => {
    const { chatList } = useContext(ChatContext)

    return (
        <Flex className='size-full relative min-h-0' align={'center'} justify={'center'}>
            {chatList?.length > 0 ? <div className='absolute flex items-baseline top-0 md:hidden'>
                <PiArrowBendLeftUpThin className='size-16' />
                <span>Select a meeting here</span>
            </div> : <div className='absolute flex items-baseline top-0'
                style={{ right: 42 }}
            >
                <span>Schedule a meeting here</span>
                <PiArrowBendRightUpThin className='size-16' />
            </div>}
            <AssistantChat />
            {/* <Heading>No chat selected</Heading> */}
        </Flex>
    )
}

export default Page
