'use client';
import MessageBase from './MessageBase';

/**
 * Show immediately for casual chats
 * Hide until event is ready to publish for event+business chats
 * @param meeetingId
 * @returns
 */


const AssistantStartMessage = () => {
    return (
        <>
            <MessageBase message={{ role: 'assistant', content: `Hi there  I'm Spire 👋, your scheduling assistant` }} />
            <MessageBase message={{ role: 'assistant', content: 'Here are some example prompts you can ask me:' }}>
                <ul className='list-disc ml-6'>
                    {/* <li>When is the earliest time I can schedule a 4 hour meeting?</li> */}
                    <li>Schedule me a 30min meeting with John (john@ethospire.com) and Wendy (wendy@ethospire.com) for sometime tomorrow afternoon</li>
                    {/* <li>I want to schedule a meeting with my professor and project group. How can you help?</li> */}
                </ul>
            </MessageBase>
        </>
    );
};

export default AssistantStartMessage;
