'use client';
import { ReactNode, useEffect, useState } from 'react';
import { Button, Flex } from '@radix-ui/themes';
import Link from 'next/link';
import ContentEditable from 'react-contenteditable';
import { Markdown } from '@/components';
import CaretNav from '@/components/CaretNav';
import { useGmail } from '@/components/Google/useGmail';
import { fetcher } from '@/lib/fetch';
import { MessageProps } from './Message';
import MessageContainer from './MessageContainer';

const HTML_REGULAR =
    /<(?!img|table|\/table|thead|\/thead|tbody|\/tbody|tr|\/tr|td|\/td|th|\/th|br|\/br).*?>/gi

const AssistantMessageBase = ((props: MessageProps & { children?: ReactNode; }) => {
    const { role, content, parts, options } = props.message;
    const isUser = role === 'user';
    const { gmailIsEnabled } = useGmail();

    const [partIndex, setPartIndex] = useState(0)
    const [subjects, setSubjects] = useState<string[]>(options?.emails.map((x: any) => x.subject) || [])
    const [messages, setMessages] = useState<string[]>(options?.emails.map((x: any) => x.email) || [])

    useEffect(() => {
        if (options?.emails) {
            setSubjects(options.emails.map((x: any) => x.subject))
            setMessages(options.emails.map((x: any) => x.email))
        }
    }, [parts?.join(',')])

    const sendAllEmail = () => {
        if (!gmailIsEnabled) {
            // TODO: also need to check if user has enabled email sending
            window.alert('You need to enable Gmail to send emails.');
            return
        }
        if (!options?.emails) {
            window.alert('No emails to send');
            return
        }
        const emails = options.emails.map((x: any, i: number) => {
            return {
                to: x.to,
                subject: subjects[i],
                content: messages[i]
            }
        })
        console.log(emails)
        // only signed in users can send emails
        return fetcher('/api/external/email/send', 'POST', {
            emails
        });
    }

    // const textAreaRef = useRef<HTMLElement>(null)

    // TODO: options not properly typed, can be an array or object
    return (
        <MessageContainer message={props.message} role={role}>
            {isUser ? (
                <div
                    className="userMessage"
                    dangerouslySetInnerHTML={{
                        __html: content.replace(
                            /<(?!\/?br\/?.+?>|\/?img|\/?table|\/?thead|\/?tbody|\/?tr|\/?td|\/?th.+?>)[^<>]*>/gi,
                            ''
                        )
                    }}
                ></div>
            ) : (
                <Flex direction="column">
                    <Flex direction="column" gap="4">
                        <Markdown>{content}</Markdown>
                        {(parts && parts.length > 0) && (!options?.emails ? <p className='whitespace-pre-wrap'>
                            {parts[partIndex]}</p> : (
                            <>
                                <span className='whitespace-pre-wrap'>{
                                    `To: ${options.emails[partIndex].to.join(', ')}`
                                }</span>
                                <span>Subject: <ContentEditable
                                    className="whitespace-pre-wrap inline"
                                    html={subjects[partIndex]}
                                    spellCheck
                                    onChange={(e) => {
                                        setSubjects(p => {
                                            const value = e.target.value.replace(HTML_REGULAR, '')
                                            return p.map((m, i) => i === partIndex ? value : m)
                                        })
                                    }}
                                /></span>
                                <ContentEditable
                                    className="whitespace-pre-wrap"
                                    html={messages[partIndex]}
                                    spellCheck
                                    onChange={(e) => {
                                        setMessages(p => {
                                            const value = e.target.value.replace(HTML_REGULAR, '')
                                            return p.map((m, i) => i === partIndex ? value : m)
                                        })
                                    }}
                                />
                                {/* <p contentEditable className='whitespace-pre-wrap'>
                                    {options.emails[partIndex].email}</p> */}
                            </>
                        ))}

                        {options?.emails && (
                            gmailIsEnabled ? <Button variant='soft' className='max-w-40 w-full mx-auto' onClick={sendAllEmail}>Send email{options.emails.length > 1 ? 's' : ''}</Button> :
                                <Link target='_blank' className='link' href={'/settings'}>Enable email sending here</Link>
                        )}
                        {props.children}
                        {/* TODO: Parts go here */}
                    </Flex>
                    <div className="mt-1 flex gap-3 empty:hidden">
                        {parts && parts.length > 1 && <CaretNav
                            value={partIndex}
                            setValue={setPartIndex}
                            total={parts.length}
                        />}
                    </div>
                </Flex>
            )}
        </MessageContainer>
    );
});

export default AssistantMessageBase;
