'use client'
import { castPromiseToUndefined } from '@planda/design-system'
import { signIn } from 'next-auth/react'
import useSWR from 'swr'
import { GMAIL_SCOPES } from '@/constants/external'
import { fetchGet, fetcher } from '@/lib/fetch'
import { GmailCredentials } from '@/services/external/types'

const PRODUCT = 'email'
export async function gmailIsEnabledFn() {
    const res = await fetchGet<{ isEnabled: boolean }>(`/api/external/${PRODUCT}/google`)
    return !!res?.isEnabled
}

export const useGmail = () => {
    const { data: gmailIsEnabled, mutate } = useSWR(`/api/external/${PRODUCT}/google`, gmailIsEnabledFn)
    const { data: gmailSettings, mutate: mutateSettings } = useSWR<GmailCredentials>(`/api/external/${PRODUCT}/google/settings`, fetchGet)

    const authorizeGoogle = async () => {
        mutate(true, false)
        await signIn('google', {}, { scope: GMAIL_SCOPES.join(' '), prompt: 'consent', access_type: 'offline' })
    }

    const disableGoogle = async () => {
        mutate(false, false)
        await fetch(`/api/external/${PRODUCT}/google`, { method: 'DELETE' })
    }


    async function editSettings(updates: Record<string, any>) {
        const remove = Object.keys(updates).filter((key) => updates[key] === undefined)
        const usedPromise = fetcher(`/api/external/${PRODUCT}/google/settings`, 'PATCH', { set: updates, remove });
        // const asyncWrapFetchGet = async () => {
        //     await usedPromise
        //     return fetchGet<CategoryRes>(`/api/main/category`)
        // }
        mutateSettings(castPromiseToUndefined(usedPromise), {
            optimisticData: (x) => (x ? { ...x, ...updates } : { access_token: 'TODO', email: 'TODO' }),
            populateCache: false,
        })
    }

    return {
        gmailIsEnabled,
        authorizeGoogle,
        disableGoogle,
        gmailSettings,
        editSettings
    }
}
