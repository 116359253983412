'use client'

/**
 * TODO: look at messages, use GPT, determine what's next.
 * First message should be calendar sync. If calendar already synced, say "Calendar synced", else show options.
 * 0. Check if calendar synced
 * 1. check if calendar credentials are still valid.
 *  - if not, prompt to re-authenticate
 * This should be it. It's just an availability filler
 */
import {
  forwardRef,
  useCallback,
  useContext,
} from 'react'
import { Flex, Heading, ScrollArea } from '@radix-ui/themes'
import toast from 'react-hot-toast'
import { fetcher } from '@/lib/fetch'
import ChatContext from './chatContext'
import AssistantMessageBase from './messages/AssistantMessageBase'
import AssistantStartMessage from './messages/AssistantStartMessage'
import Message from './messages/Message'
import SendMessage from './SendMessage'
import useChat from './useChat'
import { Chat as AssistantChat, SingleChatHandlerInstance } from '../../types/message'
import Loader from '../Loader'

import './index.scss'
const HTML_REGULAR =
  /<(?!img|table|\/table|thead|\/thead|tbody|\/tbody|tr|\/tr|td|\/td|th|\/th|br|\/br).*?>/gi

interface ChatProps { }

const postChatOrQuestion = async (chat: AssistantChat, messages: any[], input: string, userId?: string) => {
  const url = `/api/chat/assistant/individual`

  const data = {
    messages: [...messages!],
    input
  }

  return await fetcher<{ message: string, parts?: string[], options?: Record<string, any> } | undefined>(url, 'POST', data, { userid: userId })

  // return await fetch(url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data)
  // })
}
// need participant info, put in context or here? participant info probably only here, not group chat
const AssistantChat = (props: ChatProps, ref: any) => {
  const { debug, currentChatRef, user } =
    useContext(ChatContext)

  const { textAreaRef, bottomOfChatRef,
    setMessage, currentMessage, setCurrentMessage, sendMessageProps,
    isLoading, setIsLoading, conversation }
    = useChat(ref, 'individual')

  const sendMessage = useCallback(
    async (e: any) => {
      if (!isLoading) {
        e.preventDefault()
        const input = textAreaRef.current?.innerHTML?.replace(HTML_REGULAR, '') || ''

        if (input.length < 1) {
          toast.error('Please type a message to continue.')
          return
        }
        const message = [...(conversation.current || [])]
        conversation.current = [...(conversation.current || []), { content: input, role: 'user' }]
        setMessage('')
        setIsLoading(true)
        try {
          const response = await postChatOrQuestion(currentChatRef?.current!, message, input, user?.userId)

          if (response) {
            const data = response.message


            if (!data) {
              throw new Error('No data')
            }

            let resultContent = data

            if (debug) {
              console.log({ resultContent })
            }

            conversation.current = conversation.current || []
            conversation.current = [
              ...conversation.current,
              {
                content: resultContent, role: 'assistant',
                parts: response.parts || [],
                options: response.options
              }
            ]
          }

          setIsLoading(false)
        } catch (error: any) {
          console.error(error)
          toast.error(error.message)
          setIsLoading(false)
        }
      }
    },
    [currentChatRef, debug, isLoading]
  )

  return (
    <Flex direction="column" height="100%" className="relative" gap="3"
      style={{ flex: 1, position: 'relative', backgroundColor: 'var(--color-background)' }}
    >
      <Flex
        justify="between"
        align="center"
        py="3"
        px="4"
        className='min-h-0'
        style={{ backgroundColor: 'var(--gray-a2)' }}
      >
        <Heading size="4">Spire (Assistant)</Heading>
      </Flex>
      <ScrollArea
        className="flex-1 px-4"
        type="auto"
        scrollbars="vertical"
        style={{ height: '100%' }}
      >
        <AssistantStartMessage />
        {conversation.current?.map((item, index) => (
          <AssistantMessageBase key={index} message={item} />
        ))}
        {(currentMessage || isLoading) && <AssistantMessageBase isLoading={isLoading} message={{ content: currentMessage, role: 'assistant' }} />}
        <div ref={bottomOfChatRef}></div>
      </ScrollArea>
      <SendMessage
        sendMessage={sendMessage}
        {...sendMessageProps}
      />
    </Flex>
  )
}

export default forwardRef<SingleChatHandlerInstance, ChatProps>(AssistantChat)
